<template>
  <Panel
    :header="`SEJA BEM-VINDO ${this.$Token.jwtDecode().nome.toUpperCase()}`"
  >
    <DialogTermo :userSelected="user" />
    <LancamentoView />
  </Panel>
  <DialogUpdateEstabelecimento
    :objSelected="estabelecimento"
    :headerDialog="'Atualização Institucional'"
  />
</template>
<script>
import Estabelecimento from "../../models/estabelecimento";
import EstabelecimentoService from "../../service/estabelecimento/estabelecimento_service";
import UsuarioService from "../../service/user/user_service";

//Componentes
import DialogUpdateEstabelecimento from "../estabelecimento/components/dialog-form.vue";
import DialogTermo from "./components/DialogTermo.vue";

//Pages
import LancamentoView from "../lancamento/LancamentoView.vue";

export default {
  components: {
    DialogUpdateEstabelecimento,
    LancamentoView,
    DialogTermo,
  },
  data() {
    return {
      estabelecimento: new Estabelecimento(),
      estabelecimentoService: new EstabelecimentoService(),
      usuarioService: new UsuarioService(),
      user: null,
    };
  },
  created() {},
  mounted() {
    this.getUserLogged();
    this.findByIdEstalecimento();
    this.findUserById(this.$Token.jwtDecode().id);
  },
  methods: {
    findUserById(id) {
      this.usuarioService.findById(id).then((data) => {
        this.user = data;
        if (data.termo === false) {
          this.showTermo();
        }
      });
    },
    getUserLogged() {
      this.userLogged = this.$store.getters.getUserLogged;
    },
    showTermo() {
      this.$store.state.views.termo.dialogForm = true;
    },
    findByIdEstalecimento() {
      this.estabelecimentoService
        .findById(this.userLogged.estabelecimento[0].id)
        .then((data) => {
          this.checkEstabelecimento(data);
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    checkEstabelecimento(param) {
      if (!param.nome && !param.cpnj && !param.contato) {
        this.estabelecimento = param;
        this.$store.state.views.estabelecimento.dialogForm = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;

  img {
    width: 100px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }

  .product-category {
    vertical-align: middle;
    line-height: 1;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 576px) {
  .product-item {
    flex-wrap: wrap;

    .image-container {
      width: 100%;
      text-align: center;
    }

    img {
      margin: 0 0 1rem 0;
      width: 100px;
    }
  }
}
</style>
