<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '900px'}"
    header="TERMO DE USO, PRIVACIDADE, CONFIDENCIALIDADE E SIGILO"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
    :closable="false"
  >
  <!-- <div style="text-align: center;" data-mce-style="text-align: center;"><strong>TERMO DE USO, PRIVACIDADE, CONFIDENCIALIDADE E SIGILO</strong></div> -->
  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula primeira – do termo</strong></span></p>
  <p class="mb-5">
  Conforme o documento aprovado pelo Ministério da Saúde, por meio da <span style="color: rgb(207, 167, 5);" data-mce-style="color: #f1c40f;">Portaria GM Nº 271 de 27 de janeiro de 2017</span>, que dispões sobre a Política de Segurança da Informação e Comunicações do Ministério da Saúde – POSIC/MS, que estabelece diretrizes, responsabilidades e competências que visam assegurar a disponibilidade, integridade, confidencialidade, sigilo, privacidade e autenticidade das informações e comunicações, bem como a conformidade, padronização e normatização das atividades de gestão da informação e comunicações do Ministério da Saúde, celebra este <strong>TERMO</strong> que <strong>visa</strong> <strong>disciplinar e responsabilizar</strong> pelo uso dos privilégios de acesso concedidos, por meio da arquitetura de provimento de autorização - <strong>Sistema de Cadastro e Permissão de Acesso - SCPA</strong>, de um lado o <strong>Departamento de Informática do SUS - DATASUS/SE/MS</strong> e do outro, o <strong>solicitante</strong> autorizado para o acesso, denominado <strong>USUÁRIO</strong>.
  </p>

  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula segunda – dos conceitos e definições</strong></span></p>
  <p>Neste Termo, as seguintes expressões serão assim definidas:<br><br><strong>Informação Confidencial e/ou Sigilosa</strong>&nbsp;significará toda informação submetida temporariamente à restrição de acesso público em razão de sua imprescindibilidade para a segurança do seu titular, da sociedade e do Estado, e aquela abrigada pelas demais hipóteses legais de confidencialidade e sigilo.<br><br><strong>Informação</strong>&nbsp;inclui, mas não se limita, à informação relativa às documentações técnicas, relatórios técnicos, operações, instalações, equipamentos, segredos de negócio, segredo de fábrica, dados, habilidades especializadas, projetos, métodos e metodologia, sistemas, softwares, bases de dados, fluxogramas, especializações, componentes, fórmulas, produtos, amostras, diagramas, desenhos de esquema industrial, patentes e manuais.<br><br><strong>Não constituirá “Informação”</strong>&nbsp;ou&nbsp;<strong>“Informação Confidencial”</strong>&nbsp;para os propósitos deste Termo aquela que:<br><br>a. Seja de domínio público no momento da revelação ou após a revelação, exceto se isso ocorrer em decorrência de ato ou omissão da Parte Receptora;<br>b. Já esteja em poder da Parte Receptora, como resultado de sua própria pesquisa, contanto que a Parte Receptora possa comprovar esse fato;<br>c. Tenha sido legitimamente recebida de terceiros;<br>d. Seja revelada em razão de uma ordem válida ou de uma ordem judicial, somente até a extensão de tais ordens, contanto que a Parte Receptora tenha notificado a existência de tal ordem, previamente e por escrito, à Parte Reveladora, dando a esta, na medida do possível, tempo hábil para pleitear medidas de proteção que julgar cabíveis;</p>

  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula terceira – das obrigações</strong></span></p>
  <p>O USUÁRIO compromete-se:<br><br>1. A não utilizar QUAISQUER informações (Técnicas Administrativas ou Gerenciais), confidenciais ou não, a que tiver acesso, para gerar benefício próprio exclusivo e/ou unilateral, presente ou futuro, ou para o uso de terceiros;<br>2. A não efetuar nenhuma gravação ou cópia da documentação a que tiver acesso;<br>3. A não apropriar para si ou para outrem de QUALQUER material técnico, gerencial ou administrativo que venha a ser disponível;<br>4. A não repassar o conhecimento das informações, responsabilizando-se por todas as pessoas que vierem a ter acesso às informações, por seu intermédio, e obrigando-se, assim, a ressarcir a ocorrência de qualquer dano e/ou prejuízo oriundo de uma eventual quebra de sigilo ou confidencialidade de todas as informações fornecidas.<br>5. Em cuidar para que as informações confidenciais fiquem restritas ao conhecimento tão somente das pessoas que estejam diretamente envolvidos nas discussões, análises, reuniões e negócios, devendo cientificá-los da existência deste Termo e da natureza confidencial destas informações.<br><br>O DATASUS reserva-se o direito de manter as suas próprias políticas para bloqueio de usuários em casos de inatividade, atividades suspeitas ou fora do padrão normal de uso.</p>
  
  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula quarta – das penalidades</strong></span></p>

  <p>O USUÁRIO declara estar ciente das penalidades previstas na Lei Geral de Proteção de Dados nº 13.709/18, bem como das restrições previstas no artigo 31, § 2º, da Lei Federal n° 12.527/2011 (Lei de Acesso à Informação) e do § 2º, artigo 61, do Decreto n° 7.724/2012 (uso indevido da informação), no artigo 20 (divulgação autorizada ou necessária) da Lei n° 10.406/2002 (Código Civil) e nos artigos 138 a 145 (crimes contra a honra), 297, 299 e 304 (crimes de falsidade documental), do Decreto-Lei n° 2.848/1940 (Código Penal) e POSIC/MS.</p>

  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula quinta – da vigência</strong></span></p>

  <p>A vigência das obrigações assumidas e pactuadas neste&nbsp;<strong><em>Termo</em></strong>&nbsp;terá a validade definida, conforme prazos estabelecidos por cada gestor da informação acessada, podendo variar entre sistemas de informação, ou se forem decorridos mais de 60 dias sem acesso a qualquer dos sistemas do DATASUS/MS, ou ainda se for detectado o acesso por meio de qualquer outra pessoa, ou mediante autorização concedida à terceiros e/ou estranhas a este&nbsp;<strong><em>Termo</em></strong>. Pelo não cumprimento do presente&nbsp;<strong><em>Termo</em></strong>&nbsp;de Uso, Privacidade, Confidencialidade e Sigilo, fica o&nbsp;<strong>solicitante</strong>&nbsp;ciente de todas as sanções judiciais que poderão advir.</p>

  <p style="text-align: center;" data-mce-style="text-align: center;"><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;"><strong>Cláusula sexta – do foro</strong></span></p>

  <p>As partes elegem o foro de Brasília-DF, onde está localizada a sede do Ministério da Saúde para dirimir quaisquer dúvidas originadas do presente TERMO, com renúncia expressa a qualquer outro, por mais privilegiado que seja.</p>
    <template #footer>
      <Button
        label="Aceitar"
        class="p-button"
        icon="pi pi-check"
        @click="aceitarTermo(this.userSelected)"
      />
      <Button
        label="Recusar"
        icon="pi pi-times"
        class="p-button-text"
        @click="signOut()"
      />
    </template>
  </Dialog>
</template>
  <script>
//Models

//Services
import AuthService from "../../../service/auth/auth_service";
import UserService from "../../../service/user/user_service";

//VALIDATIONS

export default {
  props: ["userSelected"],
  setup() {
  },
  data() {
    return {
      authService: new AuthService(),
      submitted: false,
      teste: 0,
      userService: new UserService(),
    };
  },
  created() {},
  mounted() {
   },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.termo.dialogForm;
        return value 
      },
      set(value) {
        this.$store.state.views.termo.dialogForm = value;
      },
    },
  },
  methods: {
    aceitarTermo(user) {
      user.termo = true;
      this.userService 
      .aceitarTermo(user)
      .then((data) => {
        this.$msgSuccess(data);
        this.hideDialog();
      })
      .catch((error) => {
        this.$msgErro(error);
      });
  },
    signOut() {
      this.authService
      .logout()
      .then(() => {
        this.$router.push("/");
      })
      .catch(() => {
        
        this.$router.push("/");
      });
  },
    hideDialog() {
      this.submitted = false;
      this.visibleDialog = false;
    },
  },
};
</script>
  <style scoped></style>
  