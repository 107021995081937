<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '720px' }"
    :header="headerDialog"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
    :closable="closeDialog"
  >
    <div class="grid fluid">
      <div class="col-4 md:col-4 field">
        <label for="nome">Nome da instituição</label>
        <InputText
          id="nome"
          v-model="v$.obj.nome.$model"
          maxlength="100"
          placeholder="Informe o nome da instituição"
          :class="{ 'p-invalid': submitted && v$.obj.nome.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.nome.$invalid"
          >Nome é obrigatório.</small
        >
      </div>
      <div class="col-4 md:col-4 field">
        <label for="cnes">CNES</label>
        <InputText
          id="cnes"
          v-model="v$.obj.cnes.$model"
          maxlength="100"
          placeholder="Informe o cnes"
          :disabled="!closeDialog"
          :class="{ 'p-invalid': submitted && v$.obj.cnes.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.cnes.$invalid"
          >CNES é obrigatório.</small
        >
      </div>
      <div class="col-4 md:col-4 field">
        <label for="natureza">Natureza Jurídica</label>
        <Dropdown
          id="natureza"
          v-model="v$.obj.natureza.$model"
          optionLabel="descricao"
          :options="naturezas"
          placeholder="Selecione a natureza jurídica"
          :class="{
            'p-invalid': submitted && v$.obj.natureza.id.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.obj.natureza.id.$invalid"
          >Natureza Jurídica é obrigatório.</small
        >
      </div>
      <div class="col-4 md:col-4 field">
        <label for="cnpj">CNPJ</label>
        <InputMask
          id="cnpj"
          v-model="v$.obj.cnpj.$model"
          placeholder="Informe o cnpj"
          mask="99.999.999/9999-99"
          :class="{ 'p-invalid': submitted && v$.obj.cnpj.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.cnpj.$invalid"
          >CNPJ é obrigatório.</small
        >
      </div>
      <div class="col-4 md:col-4 field">
        <label for="contato">Contato</label>
        <InputMask
          id="contato"
          v-model="v$.obj.contato.$model"
          placeholder="Informe o contato"
          mask="(99) 9 9999-9999"
          :class="{ 'p-invalid': submitted && v$.obj.contato.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.contato.$invalid"
          >Contato é obrigatório.</small
        >
      </div>
      <div class="col-4 md:col-4 field">
        <label for="gestao">Tipo de Gestão</label>
        <Dropdown
          id="gestao"
          v-model="v$.obj.gestao.$model"
          optionLabel="descricao"
          :options="gestoes"
          placeholder="Selecione a gestão"
          :class="{
            'p-invalid': submitted && v$.obj.gestao.id.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.obj.gestao.id.$invalid"
          >Gestão é obrigatório.</small
        >
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.obj.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        v-if="closeDialog === true"
        @click="hideDialog"
      />
      <Button
        label="Sair"
        icon="pi pi pi-sign-out"
        class="p-button p-button-text"
        v-if="closeDialog === false"
        @click="signOut"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Estabelecimento from "../../../../src/models/estabelecimento";

//Services
import AuthService from "../../../service/auth/auth_service";
import EstabelecimentoService from "../../../../src/service/estabelecimento/estabelecimento_service";
import GestaoService from "../../../../src/service/gestao/gestao_service";
import NaturezaJuridicaService from "../../../../src/service/natureza/natureza_juridica_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["objSelected", "headerDialog", "closeDialog"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      authService: new AuthService(),
      obj: new Estabelecimento(),
      service: new EstabelecimentoService(),
      gestaoService: new GestaoService(),
      naturezaService: new NaturezaJuridicaService(),
      submitted: false,
      gestoes: [],
      naturezas: [],
    };
  },
  mounted() {
    this.findGestoes();
    this.findNaturezas();
  },
  validations() {
    return {
      obj: new Estabelecimento().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.estabelecimento.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.estabelecimento.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.obj.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.service
        .create(this.obj)
        .then((data) => {
          if (data.status === 201) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.service
        .update(this.obj)
        .then((data) => {
          if (data.status === 200) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    findGestoes() {
      this.gestaoService.findAll().then((data) => {
        this.gestoes = data;
      });
    },
    findNaturezas() {
      this.naturezaService.findAll().then((data) => {
        this.naturezas = data;
        this.naturezas = this.naturezas.filter(
          (item) => item.descricao != "PENDENTE"
        );
      });
    },
    hideDialog() {
      this.obj = new Estabelecimento();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
    getData() {
      if (this.objSelected) {
        this.obj = this.objSelected;
      }
    },
    signOut() {
      this.authService
        .logout()
        .then(() => {
          this.$router.push("/");
          this.hideDialog();
        })
        .catch(() => {
          this.hideDialog();
          this.$router.push("/");

        });
    },
  },
};
</script>
<style scoped></style>
